import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "gatsby";

import { examplesData } from "../components/examples/examples-data";
import ExampleThumbnail from "../components/examples/example-thumbnail.component";
import ExamplesCTA from "../components/examples/examples-cta-links.component";

import Layout from "../components/theme/layout";
import Seo from "../components/seo";

const Examples = () => {
    const [examplesList, setExamplesList] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('');

    // add default value on page load
    useEffect(() => {
        setExamplesList(examplesData);
    }, [])

    // function to get the filtered list
    function getFilteredList() {
        if (!selectedCategory) {
            return examplesList;
        }
        return examplesList.filter((example) => example.industry === selectedCategory)
    }

    return (
        <Layout>
            <Seo title='Interactive Shoppable Content Examples' />

            <PageWrapper>
                <NavigationWrapper>
                    {/* Add CTAs and filters here*/}
                    <ExamplesCTA />
                    {/* <ExamplesFilter selectedCategory={selectedCategory} setSelectedCategory={setSelectedCategory} /> */}
                    <FilterWrapper>
                        <div>
                            <ul>
                                <li
                                    style={{
                                        color: selectedCategory === '' ? '#ff5679' : '#80818B'
                                    }}
                                    onClick={() => setSelectedCategory('')}
                                    onKeyDown={() => setSelectedCategory('')}
                                >
                                    All
                                </li>
                                <li
                                    style={{
                                        color: selectedCategory === 'Fashion' ? '#ff5679' : '#80818B'
                                    }}
                                    onClick={() => setSelectedCategory('Fashion')}
                                    onKeyDown={() => setSelectedCategory('Fashion')}
                                >
                                    Fashion
                                </li>
                                <li
                                    style={{
                                        color: selectedCategory === 'Furniture' ? '#ff5679' : '#80818B'
                                    }}
                                    onClick={() => setSelectedCategory('Furniture')}
                                    onKeyDown={() => setSelectedCategory('Furniture')}
                                >
                                    Furniture
                                </li>
                            </ul>
                        </div>
                    </FilterWrapper>
                </NavigationWrapper>

                <ExamplesGrid>
                    {/* Add Grid here*/}
                    {getFilteredList().map(({key, link, customer, industry, title, thumbnail}) => (
                        <Link to={link}>
                            <ExampleThumbnail
                                title={title}
                                key={key}
                                customer={customer}
                                industry={industry}
                                thumbnail={thumbnail}
                            />
                        </Link>
                    ))}
                </ExamplesGrid>
            </PageWrapper>
        </Layout>
    )
}
const PageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 30px 100px 30px;

    @media screen and (min-width: 900px) {
        padding: 100px 50px;
        flex-direction: row;
    }

    @media screen and (min-width: 2300px) {
        max-width: 70%;
        margin: 100px auto 100px auto;
    }
`
const NavigationWrapper = styled.div`
    display: flex;
    flex-direction: column;
    min-width: 250px;
    margin-right: 130px;
`
const ExamplesGrid = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
`
const FilterWrapper = styled.div`
    @media screen and (max-width: 900px) {
        margin-bottom: 30px;
    }

    ul {
        list-style: none;
        overflow: scroll;
        --ms-overflow-style: none;
        scrollbar-width: none;

        &::-webkit-scrollbar {
            display: none;
        }

        li {
            list-style: none;
            font-family: 'Barlow Semi Condensed';
            font-weight: 600;
            font-size: 2.5rem;

            @media screen and (min-width: 900px) {
                font-size: 2.5rem;
            }


            cursor: pointer;
            transition: all 0.7s;

            &:hover {
                color: #ff5679 !important;
            }

            &.active {
                color: #ff5679;
            }
        }
    }
`
export default Examples