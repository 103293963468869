import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

const ExamplesCTA = () => {
    return (
        <CTAWrapper>
            <Link to='/pricing'>View pricing</Link>
            <Link to='/free-trial'>Try Qiwio for free</Link>
        </CTAWrapper>
    )
}
const CTAWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;

    @media screen and (max-width: 900px) {
        flex-direction: row;
        min-width: 90vw;
        margin-bottom: 30px;
    }

    a {
        color: white;
        text-align: center;
        border-radius: 3px;
        padding: 10px 20px;
        font-weight: 600;
        transition: all .4s;

        @media screen and (max-width: 900px) {
            width: 45%;
            height: 40px;
        }

        &:hover {
            transform: translateY(-5px);
        }

        &:first-child {
            border: 1px solid white;
        }

        &:last-child {
            border: 1px solid #ff5679;
            background-color: #ff5679;
        }

        &:not(:last-child) {
            margin-bottom: 10px;

            @media screen and (max-width: 900px) {
                margin-right: 10px;
                margin-bottom: 0;
            }
        }
    }
`
export default ExamplesCTA