import React from "react";
import styled from "styled-components";

const ExampleThumbnail = (data) => {

    return (
            <ThumbnailContainer>
                <img
                    layout='fullWidth'
                    src={data.thumbnail}
                    alt={data.key}
                    loading="eager"
                    placeholder='none'
                    formats={['auto', 'webp', 'avif']}
                />
                <TitleContainer>
                    <h3>{data.customer} - {data.title}</h3>
                </TitleContainer>
            </ThumbnailContainer>
    )
}

const ThumbnailContainer = styled.div`
    height: 207px;
    width: 368px;
    border-radius: 4px;
    display: grid;
    justify-items: center;
    align-items: center;
    overflow: hidden;
    cursor: pointer;

    @media screen and (max-width: 900px) {
        width: 100%;
        height: 100%;
        aspect-ratio: 16 / 9;
    }

    &:hover img {
        -webkit-filter: grayscale(0);
        filter: grayscale(0);
    }

    img {
        grid-area: 1/1;
        min-height: 100%;
        max-width: 100%;
        -webkit-filter: grayscale(100%);
        filter: grayscale(100%);
        transition: all .4s;

        @media screen and (max-width: 900px) {
            -webkit-filter: grayscale(0);
            filter: grayscale(0);
        }
    }
`
const TitleContainer = styled.div`
    padding-bottom: 20px;
    grid-area: 1/1;
    position: relative;
    align-self: flex-end;

    h3 {
        font-size: 1.2rem;
        text-align: center;
    }
`

export default ExampleThumbnail